/* eslint-disable @typescript-eslint/member-ordering */
// ---- NEW
export interface RequesterOrderDataModel {
  [key: string]: unknown
  active_buttons: string[]
  departure: string
  orderNumber: string
  orderStatus: string
  passenger: string
  pax: number
  pnr: string
  pricePerPax: number
}

export interface FlightDetailOrderDataModel {
  departing:
  {
    aircraft: string
    arrival: string
    arrivalPoint: string
    arrivalTime: string
    departure: string
    departurePoint: string
    departureTime: string
    distance: number
    flightId: string
    flightNumber: string
    flightTimeHours: number
  }[]
  number: string
  paymentMethod: string
  pnr: string
  pax: number
  flight_number: string
  returning:
  {
    aircraft: string
    arrival: string
    arrivalPoint: string
    arrivalTime: string
    departure: string
    departurePoint: string
    departureTime: string
    distance: number
    flightId: string
    flightNumber: string
    flightTimeHours: number
  }[]
}

export interface TacOrderDataModel {
  data: {
    booking_fee: string[]
    down_payment: string[]
    full_payment: string[]
    full_payment_approval: string[]
    insert_passenger: string[]
    issued_ticket: string[]
    materialisasi: string[]
    order_reduction: string[]
    others: string[]
    ticket_terms: string[]
    time_limit: string[]
  }
}

export interface PaymentGate {
  data: {
    ATM: string[]
    Mobile_Banking: string[]
  }
}

export interface SummaryPaymentInstructionDataModel {
  account_name: string
  account_number: string
  bank_name: string
  due_date: string
  order_number: string
  total: number
  payment_type: string
  inventory_type: string
  can_reduce_pax: boolean
}

export interface TablePaymentDetail {
  description: string
  unit_price: number
  quantity: number
  remark: string
  sub_total: number
}

export interface PaymentDetailbyOrderNumber {
  for_table_1: TablePaymentDetail[]
  for_table_2: TablePaymentDetail[]
  for_table_3: TablePaymentDetail[]
}

// ---- OLD
export interface TablePesananDataModel {
  [key: string]: unknown
  no_pesanan: string
  pax: number
  penumpang: string
  departure_date: string
  pnr: string
  status_pesanan: string
  harga_pax: number
}

export interface DetailPesananDataModel extends Omit<TablePesananDataModel, 'harga_pax' | 'penumpang' | 'status_pesanan'> {
  flight_id: string
  route: string
}

export interface RincianHargaDataModel {
  keterangan: string
  nominal: number
  pax: number
  total: number
  uraian: string
}

export interface PerhitunganPelunasanDataModel extends Omit<RincianHargaDataModel, 'nominal' | 'pax' | 'total'> {
  jumlah: number
}

export interface PetunjukPembayaranDataModel {
  nama_bank: string
  no_rek: string
  penerima: string
  jumlah: number
  berita: string
}

export interface KuantitasPesananDataModel {
  [key: string]: unknown
  noPesanan: string
  tanggalPesan: string
  qty: number
  pnr: string
  statusPnr: string
}

export interface PerhitunganHargaDataModel {
  [key: string]: unknown
  uraian: string
  pax: number
  nominal: number
  total: number
  keterangan: string
}

export type PembayaranBookingFeeDanUangMukaDataModel = PerhitunganHargaDataModel;

export interface PesananModel {
  main: {
    data: RequesterOrderDataModel[]
    total: number
  }
  flight: FlightDetailOrderDataModel
  tac: TacOrderDataModel
  paymentGate: PaymentGate
  paymentDetail: PaymentDetailbyOrderNumber
  //
  tablePesanan: TablePesananDataModel[]
  detailPenerbangan: DetailPesananDataModel[]
  rincianHarga: RincianHargaDataModel[]
  pelunasan: RincianHargaDataModel[]
  perhitunganPelunasan: PerhitunganPelunasanDataModel[]
  petunjukPembayaran: PetunjukPembayaranDataModel[]
  kuantitasPesanan: KuantitasPesananDataModel[]
  perhitunganHarga: PerhitunganHargaDataModel[]
  pembayaranBookingFeeDanUagMuka: PembayaranBookingFeeDanUangMukaDataModel[]
  summaryPaymentInstruction: SummaryPaymentInstructionDataModel
}

export enum PesananActionType {
  GetAllRequesterOrder = '⌘➝App➝Pesanan➝GetAllRequesterOrder',
  GetFlightDetail = '⌘➝App➝Pesanan➝GetFlightDetail',
  GetTacDetail = '⌘➝App➝Pesanan➝GetTacDetail',
  GetPayementGateDetail = '⌘➝App➝Pesanan➝GetMandiriDetail',
  GetPaymentDetail = '⌘➝App➝Pesanan➝GetPaymentDetail',
  GetSummaryPaymentInstruction = '⌘➝App➝Pesanan➝GetSummaryPaymentInstruction',
  GetDataTablePesananan = '⌘➝App➝Pesanan➝GetDataTablePesananan',
  GetDetailPenerbangan = '⌘➝App➝Pesanan➝GetDetailPesananan',
  GetRincianHarga = '⌘➝App➝Pesanan➝GetRincianHarga',
  GetPelunasan = '⌘➝App➝Pesanan➝GetPelunasan',
  GetPerhitunganPelunasan = '⌘➝App➝Pesanan➝GetPerhitunganPelunasan',
  GetPetunjukPembayaran = '⌘➝App➝Pesanan➝GetPetunjukPembayaran',
  GetKuantitasPesanan = '⌘➝App➝Pesanan➝GetKuantitasPesanan',
  GetPerhitunganHarga = '⌘➝App➝Pesanan➝GetPerhitunganHarga',
  GetPembayaranBookingFeeDanUangMuka = '⌘➝App➝Pesanan➝GetPembayaranBookingFeeDanUangMuka'
}

export type PesananAction = {
  // FLIGHT
  data: FlightDetailOrderDataModel
  type: PesananActionType.GetFlightDetail
} | {
  // PAYMENT DETAIL
  data: PaymentDetailbyOrderNumber
  type: PesananActionType.GetPaymentDetail
} | {
  // PAYMENT GATE
  data: PaymentGate
  type: PesananActionType.GetPayementGateDetail
} | {
  // REQ
  data: {
    data: RequesterOrderDataModel[]
    total: number
  }
  type: PesananActionType.GetAllRequesterOrder
} | {
  // TAC
  data: TacOrderDataModel
  type: PesananActionType.GetTacDetail
} | {
  data: DetailPesananDataModel[]
  type: PesananActionType.GetDetailPenerbangan
} | {
  data: KuantitasPesananDataModel[]
  type: PesananActionType.GetKuantitasPesanan
} | {
  data: PembayaranBookingFeeDanUangMukaDataModel[]
  type: PesananActionType.GetPembayaranBookingFeeDanUangMuka
} | {
  data: PerhitunganHargaDataModel[]
  type: PesananActionType.GetPerhitunganHarga
} | {
  data: PerhitunganPelunasanDataModel[]
  type: PesananActionType.GetPerhitunganPelunasan
} | {
  data: PetunjukPembayaranDataModel[]
  type: PesananActionType.GetPetunjukPembayaran
} | {
  data: RincianHargaDataModel[]
  type: PesananActionType.GetPelunasan
} | {
  data: RincianHargaDataModel[]
  type: PesananActionType.GetRincianHarga
} | {
  data: SummaryPaymentInstructionDataModel
  type: PesananActionType.GetSummaryPaymentInstruction
} | {
  data: TablePesananDataModel[]
  type: PesananActionType.GetDataTablePesananan
};
